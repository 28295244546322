import { Typography } from 'antd'
import { ReactGrid, Column, Row } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { useState } from 'react'

export default function Testing() {
  interface Person {
    name: string
    surname: string
  }

  const getPeople = (): Person[] => [
    { name: 'Thomas', surname: 'Goldman' },
    { name: 'Susie', surname: 'Quattro' },
    { name: '', surname: '' },
  ]

  const getColumns = (): Column[] => [
    { columnId: 'name', width: 150 },
    { columnId: 'surname', width: 150 },
  ]

  const headerRow: Row = {
    rowId: 'header',
    cells: [
      { type: 'header', text: 'Name' },
      { type: 'header', text: 'Surname' },
    ],
  }

  const getRows = (people: Person[]): Row[] => [
    headerRow,
    ...people.map<Row>((person, idx) => ({
      rowId: idx,
      cells: [
        { type: 'text', text: person.name },
        { type: 'text', text: person.surname },
      ],
    })),
  ]

  const [people] = useState<Person[]>(getPeople())

  const rows = getRows(people)
  const columns = getColumns()

  return (
    <div>
      <Typography>Testing Page</Typography>
      <div>
        <ReactGrid rows={rows} columns={columns} />
      </div>
    </div>
  )
}
