import {
  Button,
  Card,
  Checkbox,
  Drawer,
  IconButton,
  Input,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react'
import { useState } from 'react'
import AccountName from './AccountName'
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
export default function ExcelLikeTable({
  data,
  setEdit,
  setSelectedClients,
  accounts,
}) {
  type tableLine = {
    id: string
    Name: string
    VP: string[] // this will be an array of account ids.
    Director: string[]
    'Business Manager': string[]
    'Assistant Business Manager': string[]
    Insights: string[]
    'Assistant Insights Manager': string[]
    Orders: string[]
    Claims: string[]
    Assistant: string[]
    Marketing: string[]
  }
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')
  const [filterText, setFilterText] = useState('')
  const [selections, setSelections] = useState<string[]>([])
  const [buttonKey, setButtonKey] = useState<string>('')

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortDirection('asc')
    }
    refreshTable()
  }

  const handleFilterChange = (e) => {
    setFilterText(e.target.value)
    refreshTable()
  }

  const filteredData = data.filter((row) =>
    Object.values(row).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  )

  const sortedData = sortBy
    ? filteredData.sort((a, b) => {
        const aValue = a[sortBy]
        const bValue = b[sortBy]
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return aValue.localeCompare(bValue)
        } else {
          return aValue - bValue
        }
      })
    : filteredData

  if (sortDirection === 'desc') {
    sortedData.reverse()
  }
  function openEdit(edit_type) {
    setEdit(edit_type)
  }

  function toggleSelect(client_id) {
    if (selections.includes(client_id)) {
      setSelections(selections.filter((id) => id !== client_id))
    } else {
      var new_selections = selections
      new_selections.push(client_id)
      setSelections(new_selections)
    }
    setButtonKey((Math.random() + 1).toString(36).substring(7))
  }
  function redirectToEditSelection() {
    setSelectedClients(selections)
    setEdit('selection')
  }

  const [drawer, setDrawer] = useState(false)
  const openDrawer = () => {
    setDrawer(true)
  }
  const closeDrawer = () => {
    setDrawer(false)
  }

  const [hiddenColumns, setHiddenColumns] = useState<string[]>([])
  function filterCols(columns: string[]) {
    // columns are the keys of the dict for each line.
    return columns.filter((col) => !hiddenColumns.includes(col))
  }

  function toggleHiddenCol(col) {
    if (hiddenColumns.includes(col)) {
      setHiddenColumns(hiddenColumns.filter((column) => column !== col))
    } else {
      var new_hidden_cols = hiddenColumns
      new_hidden_cols.push(col)
      setHiddenColumns(new_hidden_cols)
    }
  }
  const [tableKey, setTableKey] = useState<string>('')
  function refreshTable() {
    setTableKey((Math.random() * 1000).toFixed(4))
  }

  return (
    <div className="w-full h-full">
      <div className=" text-xl font-black text-black px-4 py-2">
        Assignments
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="p-2 flex flex-row">
          <Input
            crossOrigin={'false'}
            type="text"
            label="Search"
            value={filterText}
            style={{ width: 400 }}
            onChange={handleFilterChange}
          />
          <div className="px-2">
            <IconButton placeholder={'.'} variant="text" onClick={openDrawer}>
              <AdjustmentsHorizontalIcon className="h-8 w-8" />
            </IconButton>
          </div>
        </div>
        <div className="px-4 w-fit flex flex-row">
          <div className="px-4" key={buttonKey}>
            {selections.length > 0 ? (
              <Button
                placeholder={'.'}
                color="green"
                onClick={redirectToEditSelection}
              >
                Edit by Selection
              </Button>
            ) : (
              <Button placeholder={'.'} variant="outlined" disabled>
                Edit by Selection
              </Button>
            )}
          </div>
          <div>
            <Menu>
              <MenuHandler>
                <Button placeholder=".">Quick Edit</Button>
              </MenuHandler>
              <MenuList placeholder=".">
                <MenuItem
                  placeholder="."
                  onClick={() => openEdit('find_and_replace')}
                >
                  Find and Replace
                </MenuItem>
                <MenuItem
                  placeholder="."
                  onClick={() => openEdit('match_and_replace')}
                >
                  Match and Replace
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>
      <Card placeholder={'.'} className="rounded-lg p-0 overflow-hidden">
        <table
          className="min-h-full w-full table-auto text-left p-0"
          key={tableKey}
        >
          <thead>
            <tr>
              {data[0] && (
                <>
                  <th className=" border-b border-blue-gray-100 bg-blue-gray-50 px-2 py-1 w-28"></th>
                  {filterCols(Object.keys(data[0])).map((column) => {
                    if (column !== 'id') {
                      return (
                        <th
                          key={column}
                          onClick={() => handleSort(column)}
                          className=" border-b border-blue-gray-100 bg-blue-gray-50 px-2 py-1 w-28"
                        >
                          <div className=" flex flex-row w-fit justify-between">
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none"
                            >
                              {column}
                            </Typography>
                            <div className="px-2 py-0">
                              {sortBy === column && (
                                <span>
                                  {sortDirection === 'asc' ? '↑' : '↓'}
                                </span>
                              )}
                            </div>
                          </div>
                        </th>
                      )
                    }
                  })}
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row: tableLine, index) => {
              var row_classes = 'h-14'
              return (
                <tr key={index} className={row_classes}>
                  {filterCols(Object.keys(row)).map((key: string, index) => {
                    const cell: string[] | string = row[key]

                    if (index === 0) {
                      return (
                        <td className="py-0 px-4 border">
                          <Checkbox
                            crossOrigin={'false'}
                            placeholder={'.'}
                            className="p-1"
                            onClick={() => {
                              toggleSelect(cell)
                            }}
                          />
                        </td>
                      )
                    } else if (typeof cell === 'string') {
                      return (
                        <td
                          key={index}
                          className={'py-0 px-4 border text-black'}
                        >
                          <Link to={'/view/client#' + row.id}>{cell}</Link>
                        </td>
                      )
                    } else {
                      return (
                        <td
                          key={String(index)}
                          className={'p-0 border justify-center'}
                        >
                          {cell.map((account_id, index2) => {
                            return (
                              <AccountName
                                key={index2}
                                account_id={account_id}
                                accounts={accounts}
                              />
                            )
                          })}
                        </td>
                      )
                    }
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Card>
      <Drawer
        placeholder={'.'}
        open={drawer}
        onClose={closeDrawer}
        className="p-4 shadow-lg border rounded-xl"
        placement="right"
        size={350}
      >
        <div className="w-full h-full">
          <div className="w-full flex flex-col jusfity-center py-4">
            <Typography
              placeholder={'.'}
              variant="h4"
              className="text-black pb-2"
            >
              Filter Table
            </Typography>
            <hr className=" border-blue-600" />
          </div>
          <Typography placeholder={'.'} variant="h6" className="text-black p-4">
            Columns:
          </Typography>
          <div className="p-1 flex flex-col">
            {data[0] && (
              <div className="w-full">
                {Object.keys(data[0]).map((col) => {
                  if (col !== 'Name' && col !== 'id') {
                    return (
                      <div>
                        {hiddenColumns.includes(col) ? (
                          <Checkbox
                            crossOrigin="false"
                            label={col}
                            onClick={() => toggleHiddenCol(col)}
                          />
                        ) : (
                          <Checkbox
                            crossOrigin="false"
                            defaultChecked
                            label={col}
                            onClick={() => toggleHiddenCol(col)}
                          />
                        )}
                      </div>
                    )
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  )
}
