import {
  Button,
  Card,
  Checkbox,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@material-tailwind/react'
import {
  ArrowDownTrayIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import { client } from '../../types/client'
import { account } from '../../types/account'
import { useContext, useEffect, useState } from 'react'
import {
  AccountsContext,
  ClientsContext,
  ReloadDataContext,
} from '../../contexts'
import { additionalInfo } from '../../types/additionalInfo'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../firebase'
import XLSX from 'xlsx'
import Loading from '../../components/Loading'

type accountConfig = {
  id: boolean
  'Cell Phone': boolean
  City: boolean
  Ext: boolean
  State: boolean
  company: boolean
  email: boolean
  internal: boolean
  name: boolean
  phone: boolean
  title: boolean
  avatar: boolean
}
type clientConfig = {
  active: boolean
  potential_client: boolean
  aliases: boolean
  brands: boolean
  commission_file: boolean
  contacts: boolean
  roles: boolean
  end_date: boolean
  start_date: boolean
  file_id: boolean
  id: boolean
  logo: boolean
  name: boolean
  tier: boolean
}

export default function Exports() {
  const clients: client[] = useContext(ClientsContext)
  const accounts: account[] = useContext(AccountsContext)
  const [additionalInfoDocs, setAdditionalInfoDocs] =
    useState<additionalInfoSet>({})
  const [reportType, setReportType] = useState('')

  type additionalInfoSet = {
    [key: string]: additionalInfo
  }

  async function getAdditionalInfoDocs() {
    var temp_docs: additionalInfoSet = {}
    await (
      await getDocs(collection(db, 'additional_client_info'))
    ).forEach((doc) => {
      var additional_info_template: additionalInfo = {
        comments: '',
        documents: {},
        logins: [],
        broker_portal: false,
        marketing: {
          kpm: '',
          active_programs: '',
        },
      }
      const data = doc.data()
      const keys = Object.keys(data)
      for (let i = 0; i < keys.length; i++) {
        additional_info_template[keys[i]] = data[keys[i]]
      }
      temp_docs[doc.id] = additional_info_template
    })
    setAdditionalInfoDocs(temp_docs)
  }

  const [accountReportConfig, setAcctReportConfig] = useState<accountConfig>({
    id: false,
    'Cell Phone': false,
    City: false,
    Ext: false,
    State: false,
    company: false,
    email: false,
    internal: false,
    name: false,
    phone: false,
    title: false,
    avatar: false,
  })

  const [clientReportConfig, setClientReportConfig] = useState<clientConfig>({
    active: false,
    potential_client: false,
    aliases: false,
    brands: false,
    commission_file: false,
    contacts: false,
    roles: false,
    end_date: false,
    start_date: false,
    file_id: false,
    id: false,
    logo: false,
    name: false,
    tier: false,
  })

  function resetConfigs() {
    // the check boxes refresh on each change to the report type, reseting them to be all unchecked.
    // the configs need to follow suit.
    setAcctReportConfig({
      id: false,
      'Cell Phone': false,
      City: false,
      Ext: false,
      State: false,
      company: false,
      email: false,
      internal: false,
      name: false,
      phone: false,
      title: false,
      avatar: false,
    })
    setClientReportConfig({
      active: false,
      potential_client: false,
      aliases: false,
      brands: false,
      commission_file: false,
      contacts: false,
      roles: false,
      end_date: false,
      start_date: false,
      file_id: false,
      id: false,
      logo: false,
      name: false,
      tier: false,
    })
  }

  const [firstLoad, setFirstLoad] = useState(true)
  const reloadData = useContext(ReloadDataContext)
  const [readyForRendering, setReadyForRendering] = useState(false)
  // @ts-ignore: Unreachable code error
  useEffect(() => {
    if (firstLoad && clients && accounts && reloadData) {
      setFirstLoad(false)
      getAdditionalInfoDocs()
      reloadData()
      setReadyForRendering(true)
    }
  }, [
    reloadData,
    firstLoad,
    setFirstLoad,
    getAdditionalInfoDocs,
    accounts,
    clients,
    setReadyForRendering,
  ])

  const account_fields = [
    'name',
    'id',
    'internal',
    'email',
    'phone',
    'Cell Phone',
    'Ext',
    'City',
    'State',
    'title',
    'company',
  ]
  const client_fields = [
    'name',
    'id',
    'file_id',
    'start_date',
    'end_date',
    'active',
    'potential_client',
    'tier',
    'commission_file',
    'aliases',
    'brands',
    'contacts',
    'roles',
  ]

  function downloadMarketingReport(accounts: account[], clients: client[]) {
    // sheet parameters:
    // client name
    // vp
    // dir
    // bm
    // marketing
    // kpm
    // active programs
    try {
      // create file name
      var file_name = ''
      var process_time_stamp = ''
      const currentDate = new Date()
      const currentDayOfMonth = currentDate.getDate()
      const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
      const currentYear = currentDate.getFullYear()
      process_time_stamp =
        currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
      file_name = 'Orbit Marketing Tracker ' + process_time_stamp

      // create final json
      var final_json: Array<string[]> = []
      // filters all accounts by selected filters.
      var refined_clients: client[] = clients
      refined_clients = refined_clients.filter((client) => {
        return client.active
      })

      // writes array to final_json based on selected fields.
      for (let index = 0; index < refined_clients.length; index++) {
        const client = refined_clients[index]
        var line: string[] = []

        line.push(client.name)

        var vp_name = ''
        var dir_name = ''
        var bm_name = ''
        var marketing_name = ''
        if (client.roles !== undefined) {
          if (client.roles['VP'] !== undefined) {
            const ids = client.roles['VP']
            for (let i = 0; i < accounts.length; i++) {
              if (ids.includes(accounts[i].id)) {
                if (ids.length > 1) {
                  vp_name += findAccountName(accounts[i].id, accounts) + ', '
                } else {
                  vp_name = findAccountName(accounts[i].id, accounts)
                }
              }
            }
          }
          if (client.roles['Director'] !== undefined) {
            const ids = client.roles['Director']
            for (let i = 0; i < accounts.length; i++) {
              if (ids.includes(accounts[i].id)) {
                if (ids.length > 1) {
                  dir_name += findAccountName(accounts[i].id, accounts) + ', '
                } else {
                  dir_name = findAccountName(accounts[i].id, accounts)
                }
              }
            }
          }
          if (client.roles['Business Manager'] !== undefined) {
            const ids = client.roles['Business Manager']
            for (let i = 0; i < accounts.length; i++) {
              if (ids.includes(accounts[i].id)) {
                if (ids.length > 1) {
                  bm_name += findAccountName(accounts[i].id, accounts) + ', '
                } else {
                  bm_name = findAccountName(accounts[i].id, accounts)
                }
              }
            }
          }
          if (client.roles['Marketing'] !== undefined) {
            const ids = client.roles['Marketing']
            for (let i = 0; i < accounts.length; i++) {
              if (ids.includes(accounts[i].id)) {
                if (ids.length > 1) {
                  marketing_name +=
                    findAccountName(accounts[i].id, accounts) + ', '
                } else {
                  marketing_name = findAccountName(accounts[i].id, accounts)
                }
              }
            }
          }
        }
        line.push(vp_name)
        line.push(dir_name)
        line.push(bm_name)
        line.push(marketing_name)

        var additional_info_template: additionalInfo = {
          comments: '',
          documents: {},
          logins: [],
          broker_portal: false,
          marketing: {
            kpm: '',
            active_programs: '',
          },
        }
        for (let key in additionalInfoDocs) {
          if (key === client.id) {
            additional_info_template = additionalInfoDocs[key]
          }
        }
        line.push(additional_info_template.marketing.kpm)
        line.push(additional_info_template.marketing.active_programs)
        final_json.push(line)
      }

      //write json to csv
      var headers: string[] = [
        'Client Name',
        'VP',
        'Dir',
        'Business Manager',
        'Marketing',
        'KPM Status',
        'Active Programs',
      ]
      //trigger download
      downloadCSVfromString(file_name, final_json, headers)
    } catch (err) {
      alert(
        'An error occured while collecting the data for download. Please try again or contact support.'
      )
      console.log(err)
    }
  }

  function toggleField(field, type) {
    if (type === 'client') {
      setClientReportConfig({
        ...clientReportConfig,
        [field]: !clientReportConfig[field],
      })
    } else {
      setAcctReportConfig({
        ...accountReportConfig,
        [field]: !accountReportConfig[field],
      })
    }
  }

  const [fieldsTableKey, setFieldTableKey] = useState('none')
  function refreshFields() {
    if (fieldsTableKey === 'none') {
      setFieldTableKey('something')
    } else {
      setFieldTableKey('none')
    }
    // this function is to refresh the fields table. The actual value is irrelevant aslong as the key changes.
  }

  const report_descriptions = {
    general_client: `
        Downloads all active clients in the system.`,
    employees: `Downloads a list of all the employees in Orbit.`,
    contacts: `Downloads all of the contacts listed in the system, 
        their general information, and what titles they occupy on active clients. 
        (Such as Primary Contact or Invoice Contact)`,
    marketing:
      'The marketing team has trackers on client pages. This report pulls those trackers together and lists them by client.',
    primary_per_bm:
      'This report merges contacts and employees based on active clients. Each row will have a client, their primary contact, and the Business Manager when available.',
  }

  const reports = [
    {
      name: 'General Client Report',
      download: downloadGeneralClientReport,
      desc_id: 'general_client',
    },
    {
      name: 'OSMG Employees Report',
      download: downloadEmployeesReport,
      desc_id: 'employees',
    },
    {
      name: 'Contacts Report',
      download: downloadContactsReport,
      desc_id: 'contacts',
    },
    {
      name: 'Marketing Tracker',
      download: downloadMarketingReport,
      desc_id: 'marketing',
    },
    {
      name: 'Primary Contacts Report',
      download: downloadPrimaryPerBMReport,
      desc_id: 'primary_per_bm',
    },
  ]

  const [currentDescId, setCurrentDescID] = useState('general_client')
  const [description, setDescription] = useState(
    report_descriptions['general_client']
  )
  function showDescription(desc_id) {
    setDescription(report_descriptions[desc_id])
    setCurrentDescID(desc_id)
  }

  return (
    <Card placeholder="." className="flex h-fit w-full flex-col p-4">
      <div className="flex flex-row w-full h-96 pb-4">
        <Card
          className="border w-1/2 h-full overflow-auto p-2"
          placeholder={'.'}
        >
          <Typography variant="h5" placeholder={'.'} className="text-black">
            Reports
          </Typography>
          <div className="pt-2 pb-2">
            <hr className="border-blue-600 w-full" />
          </div>
          <div className="flex flex-col">
            {!readyForRendering ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                {reports.map((report_obj) => {
                  return (
                    <button
                      className="p-2  hover:bg-blue-100 w-full rounded-md"
                      onMouseEnter={() => {
                        showDescription(report_obj.desc_id)
                      }}
                      onClick={() => {
                        report_obj.download(accounts, clients)
                      }}
                    >
                      <div>
                        {currentDescId === report_obj.desc_id ? (
                          <div className="text-black font-bold">
                            {report_obj.name}
                          </div>
                        ) : (
                          <div className="text-black">{report_obj.name}</div>
                        )}
                      </div>
                    </button>
                  )
                })}
              </>
            )}
          </div>
        </Card>
        <div className="w-2"></div>
        <Card
          className="border w-1/2 h-full overflow-auto p-2"
          placeholder={'.'}
        >
          <Typography variant="h5" placeholder={'.'} className="text-black">
            Description
          </Typography>
          <div className="pt-2 pb-2">
            <hr className="border-blue-600 w-full" />
          </div>
          <div className="p-1 text-black">{description}</div>
        </Card>
      </div>
      <Card className="border w-1/2 h-full overflow-auto p-2" placeholder={'.'}>
        <div className="flex flex-row">
          <Typography variant="h5" placeholder={'.'} className="text-black">
            Custom Report
          </Typography>
          <Tooltip
            placement="right"
            className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
            content={
              <div className="w-80">
                <Typography
                  placeholder={'.'}
                  color="blue-gray"
                  className="font-medium"
                >
                  Custom Exports can be Strange
                </Typography>
                <Typography
                  placeholder={'.'}
                  variant="small"
                  color="blue-gray"
                  className="font-normal opacity-80"
                >
                  Reports from this section, will not filter by only Active
                  Clients or other helpful cleaning steps. Pay close attention
                  when using the custom exports, and contact support through the
                  dropdown in the upper left if you have any questions.
                </Typography>
              </div>
            }
          >
            <ExclamationTriangleIcon className="h-6 w-10 pl-4 pt-1 stroke-yellow-700" />
          </Tooltip>
        </div>
        <div className="pt-2 pb-2">
          <hr className="border-blue-600 w-full" />
        </div>

        <div className="w-full">
          <div className="p-2">
            <div>
              <div>
                <Menu placement="left">
                  <MenuHandler>
                    <Button placeholder={'.'} variant="outlined">
                      Report Type: {reportType}
                    </Button>
                  </MenuHandler>
                  <MenuList placeholder={'.'}>
                    <MenuItem
                      placeholder={'.'}
                      onClick={() => {
                        setReportType('By Client')
                        refreshFields()
                        resetConfigs()
                      }}
                    >
                      By Client
                    </MenuItem>
                    <MenuItem
                      placeholder={'.'}
                      onClick={() => {
                        setReportType('By Account')
                        refreshFields()
                        resetConfigs()
                      }}
                    >
                      By Account
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
              {reportType !== '' && (
                <>
                  <div
                    className="py-4 flex flex-row"
                    key={'fields list for ' + fieldsTableKey}
                  >
                    <div className="flex flex-col w-full">
                      <Typography placeholder={'.'} variant="h5">
                        Fields
                      </Typography>
                      <div>
                        {reportType === 'By Client' ? (
                          <div>
                            {client_fields.map((title, index) => {
                              return (
                                <div key={'field' + index}>
                                  <Checkbox
                                    crossOrigin={'false'}
                                    label={title}
                                    onClick={() => {
                                      toggleField(title, 'client')
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          <div className="">
                            {account_fields.map((title, index) => {
                              return (
                                <div key={'field' + index}>
                                  <Checkbox
                                    crossOrigin={'false'}
                                    label={title}
                                    onClick={() => {
                                      toggleField(title, 'account')
                                    }}
                                  />
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center">
                    <IconButton
                      placeholder={'.'}
                      onClick={() => {
                        if (reportType === 'By Client') {
                          downloadClientReport(
                            clientReportConfig,
                            clients,
                            accounts
                          )
                        } else {
                          downloadAccountReport(
                            accountReportConfig,
                            clients,
                            accounts
                          )
                        }
                      }}
                      variant="outlined"
                    >
                      <ArrowDownTrayIcon className="w-6 h-6" />
                    </IconButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Card>
    </Card>
  )
}
function downloadClientReport(
  config: clientConfig,
  clients: client[],
  accounts: account[]
) {
  try {
    // create file name
    var file_name = ''
    var process_time_stamp = ''
    const currentDate = new Date()
    const currentDayOfMonth = currentDate.getDate()
    const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear()
    process_time_stamp =
      currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
    file_name = 'Orbit Custom Client Report ' + process_time_stamp

    // contains all info in prep for download
    var final_json: Array<string[]> = []

    var refined_clients: client[] = clients

    var headers: string[] = []

    // write simple field headers
    const client_single_fields = [
      'name',
      'id',
      'file_id',
      'start_date',
      'end_date',
      'active',
      'potential_client',
      'tier',
      'commission_file',
      'aliases',
      'brands',
    ]
    for (let i = 0; i < client_single_fields.length; i++) {
      if (config[client_single_fields[i]]) {
        headers.push(client_single_fields[i])
      }
    }

    // write role headers if in config
    const roles = [
      'VP',
      'Director',
      'Business Manager',
      'Assistant Business Manager',
      'Insights',
      'Assistant Insights Manager',
      'Orders',
      'Claims',
      'Marketing',
      'Assistant',
    ]
    if (config['roles']) {
      headers = headers.concat(roles)
    }

    // write contacts if in config
    const contacts = [
      'Primary Contact',
      'Marketing or Creative Contact',
      'Billing Contact',
      'Sales Contact',
      'Operations Contact',
      'Invoice Contact',
    ]
    if (config['contacts']) {
      headers = headers.concat(contacts)
    }

    // for client in clients, write fields
    for (let index = 0; index < refined_clients.length; index++) {
      const client = refined_clients[index]
      var line: string[] = []

      const client_single_fields = [
        'name',
        'id',
        'file_id',
        'start_date',
        'end_date',
        'active',
        'potential_client',
        'tier',
        'commission_file',
      ]
      for (let i = 0; i < client_single_fields.length; i++) {
        if (config[client_single_fields[i]]) {
          if (client[client_single_fields[i]]) {
            line.push(String(client[client_single_fields[i]]))
          } else {
            line.push('')
          }
        }
      }

      // write aliases and brands if in config
      if (config['aliases']) {
        if (client['aliases']) {
          line.push(client['aliases'].join(', '))
        } else {
          line.push('')
        }
      }
      if (config['brands']) {
        if (client['brands']) {
          line.push(client['brands'].join(', '))
        } else {
          line.push('')
        }
      }

      // write roles if in config
      if (config['roles']) {
        if (client['roles']) {
          for (let i = 0; i < roles.length; i++) {
            if (client['roles'][roles[i]]) {
              line.push(
                getAccountNames(client['roles'][roles[i]], accounts).join(', ')
              )
            } else {
              line.push('')
            }
          }
        } else {
          for (let i = 0; i < roles.length; i++) {
            line.push('')
          }
        }
      }

      // write contacts if in config
      if (config['contacts']) {
        if (client['contacts']) {
          for (let i = 0; i < contacts.length; i++) {
            if (client['contacts'][contacts[i]]) {
              line.push(
                getAccountNames(client['contacts'][contacts[i]], accounts).join(
                  ', '
                )
              )
            } else {
              line.push('')
            }
          }
        } else {
          for (let i = 0; i < contacts.length; i++) {
            line.push('')
          }
        }
      }
      final_json.push(line)
    }

    //trigger download
    downloadCSVfromString(file_name, final_json, headers)
  } catch (err) {
    alert(
      'An error occured while collecting the data for download. Please try again or contact support.'
    )
    console.log(err)
  }
}
function downloadAccountReport(
  config: accountConfig,
  clients: client[],
  accounts: account[]
) {
  try {
    // create file name
    var file_name = ''
    var process_time_stamp = ''
    const currentDate = new Date()
    const currentDayOfMonth = currentDate.getDate()
    const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear()
    process_time_stamp =
      currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
    file_name = 'Orbit Custom Accounts Report ' + process_time_stamp

    // create final json
    var final_json: string[][] = []

    var refined_accounts: account[] = accounts

    var headers: string[] = []

    var account_fields = [
      'name',
      'id',
      'internal',
      'email',
      'phone',
      'Cell Phone',
      'Ext',
      'City',
      'State',
      'title',
      'company',
    ]

    for (let i = 0; i < account_fields.length; i++) {
      if (config[account_fields[i]]) {
        headers.push(account_fields[i])
      }
    }

    for (let i = 0; i < refined_accounts.length; i++) {
      const account = refined_accounts[i]

      var line: string[] = []

      for (let k = 0; k < account_fields.length; k++) {
        if (config[account_fields[k]]) {
          if (account_fields[k] !== 'company') {
            if (account[account_fields[k]]) {
              line.push(account[account_fields[k]])
            } else {
              line.push('')
            }
          } else {
            if (account.internal === true) {
              line.push('On Shelf Marketing Group')
            } else {
              line.push(getCompany(account.id, clients))
            }
          }
        }
      }
      final_json.push(line)
    }

    //trigger download
    downloadCSVfromString(file_name, final_json, headers)
  } catch (err) {
    alert(
      'An error occured while collecting the data for download. Please try again or contact support.'
    )
    console.log(err)
  }
}
function downloadGeneralClientReport(accounts: account[], clients: client[]) {
  var final_json: object[] = []

  const active_clients = clients.filter((client) => {
    return client.active
  })

  var all_role_titles = [
    'VP',
    'Director',
    'Business Manager',
    'Assistant Business Manager',
    'Insights',
    'Assistant Insights Manager',
    'Orders',
    'Claims',
    'Marketing',
    'Assistant',
  ]
  var all_contact_titles = [
    'Primary Contact',
    'Marketing or Creative Contact',
    'Billing Contact',
    'Sales Contact',
    'Operations Contact',
    'Invoice Contact',
  ]

  active_clients.forEach((client) => {
    if (client.roles) {
      Object.keys(client.roles).forEach((key) => {
        if (!all_role_titles.includes(key)) {
          all_role_titles.push(key)
        }
      })
    }
    if (client.contacts) {
      Object.keys(client.contacts).forEach((key) => {
        if (!all_contact_titles.includes(key)) {
          all_contact_titles.push(key)
        }
      })
    }
  })
  active_clients.forEach((client) => {
    var new_entry = {
      Name: client?.name,
      ID: client?.file_id,
      'Start Date': client?.start_date,
      'End Date': client?.end_date,
      'Commissions File': client?.commission_file,
    }

    all_role_titles.forEach((key) => {
      if (client.roles) {
        if (client.roles[key]) {
          const account_names = getAccountNames(client.roles[key], accounts)
          new_entry[key] = account_names.join(', ')
        } else {
          new_entry[key] = ''
        }
      } else {
        new_entry[key] = ''
      }
    })
    all_contact_titles.forEach((key) => {
      if (client.contacts) {
        if (client.contacts[key]) {
          const account_names = getAccountNames(client.contacts[key], accounts)
          new_entry[key] = account_names.join(', ')
        } else {
          new_entry[key] = ''
        }
      } else {
        new_entry[key] = ''
      }
    })
    final_json.push(new_entry)
  })

  var file_name = ''
  var process_time_stamp = ''
  const currentDate = new Date()
  const currentDayOfMonth = currentDate.getDate()
  const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
  const currentYear = currentDate.getFullYear()
  process_time_stamp =
    currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
  file_name = 'Orbit General Client Report ' + process_time_stamp

  downloadExcelFromJSON(file_name, final_json)
}

function downloadPrimaryPerBMReport(accounts: account[], clients: client[]) {
  // TODO
  var final_json: object[] = []

  const active_clients = clients.filter((client) => {
    return client.active
  })

  var all_role_titles = ['Business Manager']
  var all_contact_titles = ['Primary Contact', 'Sales Contact']

  active_clients.forEach((client) => {
    var new_entry = {
      Name: client?.name,
      ID: client?.file_id,
      'Start Date': client?.start_date,
      'End Date': client?.end_date,
      'Commissions File': client?.commission_file,
    }

    all_role_titles.forEach((key) => {
      if (client.roles) {
        if (client.roles[key]) {
          const account_names = getAccountNames(client.roles[key], accounts)
          new_entry[key] = account_names.join(', ')
          const account_emails = getAccountEmails(client.roles[key], accounts)
          new_entry[key + ' Email'] = account_emails.join(', ')
          const account_phones = getAccountPhones(client.roles[key], accounts)
          new_entry[key + ' Phone #'] = account_phones.join(', ')
        } else {
          new_entry[key] = ''
          new_entry[key + ' Email'] = ''
          new_entry[key + ' Phone #'] = ''
        }
      } else {
        new_entry[key] = ''
        new_entry[key + ' Email'] = ''
        new_entry[key + ' Phone #'] = ''
      }
    })
    all_contact_titles.forEach((key) => {
      if (client.contacts) {
        if (client.contacts[key]) {
          const account_names = getAccountNames(client.contacts[key], accounts)
          new_entry[key] = account_names.join(', ')
          const account_emails = getAccountEmails(
            client.contacts[key],
            accounts
          )
          new_entry[key + ' Email'] = account_emails.join(', ')

          const account_phones = getAccountPhones(
            client.contacts[key],
            accounts
          )
          new_entry[key + ' Phone #'] = account_phones.join(', ')
        } else {
          new_entry[key] = ''
          new_entry[key + ' Email'] = ''
          new_entry[key + ' Phone #'] = ''
        }
      } else {
        new_entry[key] = ''
        new_entry[key + ' Email'] = ''
        new_entry[key + ' Phone #'] = ''
      }
    })
    final_json.push(new_entry)
  })

  var file_name = ''
  var process_time_stamp = ''
  const currentDate = new Date()
  const currentDayOfMonth = currentDate.getDate()
  const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
  const currentYear = currentDate.getFullYear()
  process_time_stamp =
    currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
  file_name = 'Orbit Primary Contacts Report' + process_time_stamp

  downloadExcelFromJSON(file_name, final_json)
}

// TODO this needs to be converted to the new json to excel download process. Also this should include a title matrix with what roles they occupy.
function downloadEmployeesReport(accounts: account[], clients: client[]) {
  try {
    // create file name
    var file_name = ''
    var process_time_stamp = ''
    const currentDate = new Date()
    const currentDayOfMonth = currentDate.getDate()
    const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear()
    process_time_stamp =
      currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
    file_name = 'Orbit Employees Report ' + process_time_stamp

    // create final json
    var final_json: string[][] = []
    // filters all accounts by selected filters.
    var refined_accounts: account[] = JSON.parse(JSON.stringify(accounts))

    // writes array to final_json based on selected fields.
    refined_accounts.forEach((account: account) => {
      if (account.internal) {
        var line: string[] = []

        const keys = ['name', 'email', 'phone', 'Cell Phone']
        keys.forEach((key) => {
          if (account[key]) {
            line.push(account[key])
          } else {
            line.push('')
          }
        })

        if (account.id) {
          line.push('')
        } else {
          line.push('')
        }
        final_json.push(line)
      }
    })

    //write json to csv
    var headers: string[] = ['Name', 'Email', 'Phone', 'Cell Phone']
    //trigger download
    downloadCSVfromString(file_name, final_json, headers)
  } catch (err) {
    alert(
      'An error occured while collecting the data for download. Please try again or contact support.'
    )
    console.log(err)
  }
}
// make sure this exclusively adds role true on active clients.
function downloadContactsReport(accounts: account[], clients: client[]) {
  try {
    // create file name
    var file_name = ''
    var process_time_stamp = ''
    const currentDate = new Date()
    const currentDayOfMonth = currentDate.getDate()
    const currentMonth = currentDate.getMonth() // Be careful! January is 0, not 1
    const currentYear = currentDate.getFullYear()
    process_time_stamp =
      currentDayOfMonth + '-' + (currentMonth + 1) + '-' + currentYear
    file_name = 'Orbit Contacts Report ' + process_time_stamp

    // filters all accounts by selected filters.
    var refined_accounts: account[] = JSON.parse(JSON.stringify(accounts))
    refined_accounts = refined_accounts.filter((account) => {
      return !account.internal
    })

    // filter accounts to only have contacts with active clients.
    var all_active_contacts: string[] = []
    var client_name_per_contact_id: Object = {}
    var client_commission_file_per_contact_id: Object = {}
    clients.forEach((client) => {
      if (client.contacts && client.active) {
        Object.keys(client.contacts).forEach((key) => {
          client.contacts[key].forEach((acct_id) => {
            if (!all_active_contacts.includes(acct_id))
              all_active_contacts.push(acct_id)
            client_name_per_contact_id[acct_id] = client.name
            client_commission_file_per_contact_id[acct_id] =
              client.commission_file || ''
          })
        })
      }
    })

    refined_accounts = refined_accounts.filter((account) => {
      return all_active_contacts.includes(account.id)
    })

    // assemble general information for each account.
    var final_json: Object[] = []
    refined_accounts.forEach((account) => {
      final_json.push({
        Name: account.name || 'unnamed',
        Phone: account.phone || '',
        'Cell Phone': account['Cell Phone'] || '',
        Id: account.id || '',
        Email: account.email || '',
        Title: account.title || '',
        Company: client_name_per_contact_id[account.id] || '',
        'Company Commission File':
          client_commission_file_per_contact_id[account.id] || '',
        City: account.City || '',
        Ext: account.Ext || '',
        State: account.State || '',
        'Primary Contact': '',
        'Marketing or Creative Contact': '',
        'Billing Contact': '',
        'Sales Contact': '',
        'Operations Contact': '',
        'Invoice Contact': '',
      })
    })

    // collect all possible titles and add to json.
    var contact_titles: string[] = []
    clients.forEach((client) => {
      if (client.contacts) {
        Object.keys(client.contacts).forEach((key) => {
          if (!contact_titles.includes(key)) {
            contact_titles.push(key)
          }
        })
      }
    })

    var titles_by_account_id = {}
    clients.forEach((client) => {
      if (client.contacts) {
        Object.keys(client.contacts).forEach((key) => {
          if (Object.keys(titles_by_account_id).includes(key)) {
            titles_by_account_id[key] = titles_by_account_id[key].concat(
              client.contacts[key]
            )
            console.log(
              'Title:',
              key,
              'Already Existed and will now also have:',
              client.contacts[key]
            )
          } else {
            console.log(
              'Title:',
              key,
              'Did not exist and will now have:',
              client.contacts[key]
            )
            titles_by_account_id[key] = client.contacts[key]
          }
        })
      }
    })
    console.log(titles_by_account_id)

    var final_json_with_title_matrix: object[] = []
    final_json.forEach((refined_account) => {
      const account_id = refined_account['Id']
      var current_obj = refined_account

      Object.keys(titles_by_account_id).forEach((title) => {
        if (!(title in current_obj)) {
          current_obj[title] = ''
        }
        if (titles_by_account_id[title].includes(account_id)) {
          current_obj[title] = 'true'
        }
      })
      final_json_with_title_matrix.push(current_obj)
    })
    console.log(final_json_with_title_matrix)

    // add client title information

    downloadExcelFromJSON(file_name, final_json_with_title_matrix)
  } catch (err) {
    alert(
      'An error occured while collecting the data for download. Please try again or contact support.'
    )
    console.log(err)
  }
}

function downloadExcelFromJSON(file_name: string, final_json) {
  var worksheet = XLSX.utils.json_to_sheet(final_json)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, file_name + '.xlsb')
}
function downloadCSVfromString(
  file_name: string,
  rows: string[][],
  headers: string[]
) {
  //write rows to csv while escaping commas in values.
  var final_json: object[] = []
  for (let k = 0; k < rows.length; k++) {
    var dict = {}
    for (let i = 0; i < headers.length; i++) {
      if (rows[k][i]) {
        dict[headers[i]] = rows[k][i]
      } else {
        dict[headers[i]] = rows[k][i]
      }
    }
    final_json.push(dict)
  }

  var worksheet = XLSX.utils.json_to_sheet(final_json)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, file_name + '.xlsb')
}
function getCompany(account_id: string, clients: client[]) {
  var company_name = ''
  clients.forEach((client) => {
    if (client.contacts) {
      Object.keys(client.contacts).forEach((key) => {
        if (client.contacts[key].includes(account_id)) {
          company_name = client.name
        }
      })
    }
  })

  return company_name
}
function findAccountName(account_id: string, accounts: account[]) {
  var account_name = ''
  for (let i = 0; i < accounts.length; i++) {
    if (accounts[i].id === account_id) {
      account_name = accounts[i].name
    }
  }
  return account_name
}
function getAccountNames(account_ids: string[], accounts: account[]) {
  var account_names: string[] = []
  for (let k = 0; k < account_ids.length; k++) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === account_ids[k]) {
        account_names.push(accounts[i].name)
      }
    }
  }
  return account_names
}
function getAccountEmails(account_ids: string[], accounts: account[]) {
  var account_emails: string[] = []
  for (let k = 0; k < account_ids.length; k++) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === account_ids[k]) {
        account_emails.push(accounts[i].email)
      }
    }
  }
  return account_emails
}
function getAccountPhones(account_ids: string[], accounts: account[]) {
  var account_phones: string[] = []
  for (let k = 0; k < account_ids.length; k++) {
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === account_ids[k]) {
        const account_phone = accounts[i].phone ? accounts[i].phone : ''
        if (account_phone) {
          account_phones.push(account_phone)
        }
      }
    }
  }
  return account_phones
}
