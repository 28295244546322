import {
  Card,
  Typography,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react'
import { useContext, useEffect, useState } from 'react'
import '../App.css'
import Loading from '../components/Loading'
import { Navigate } from 'react-router-dom'
import {
  AccountsContext,
  ClientsContext,
  CurrentUserContext,
  ReloadDataContext,
} from '../contexts'
import { account } from '../types/account'
import { client } from '../types/client'
import { change } from '../types/change'
import upkeep from '../functions/upkeep'
import ClientCard from '../components/CleintCard'
import { collection, getDocs, limit, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import ensureChangeType from '../functions/ensureType/ensureChangeType'

export default function Home() {
  const account: account = useContext(CurrentUserContext)
  const clients: client[] = useContext(ClientsContext)
  const accounts: account[] = useContext(AccountsContext)
  const [firstLoad, setFirstLoad] = useState(true)
  const [myChanges, setMyChanges] = useState<change[]>()
  const [recentChanges, setRecentChanges] = useState<change[]>()

  // new
  const [allRefClientIDsByTitle, setAllRefClientIDsByTitle] = useState({})
  const [allTitlesInOrder, setAllTitlesInOrder] = useState<
    string[] | undefined
  >()
  const [accordion, setAccordion] = useState({})
  function sortTitles(all_titles: string[]) {
    const title_refs = {
      VP: 0,
      Director: 1,
      'Business Manager': 2,
      'Assistant Business Manager': 3,
      'Insights Manager': 4,
      'Assistant Insights Manager': 5,
      Orders: 6,
      Claims: 7,
      Marketing: 8,
      'Primary Contact': 9,
      'Marketing or Creative Contact': 10,
      'Billing Contact': 11,
      'Sales Contact': 12,
      'Operations Contact': 13,
      'Invoice Contact': 14,
    }

    return all_titles.sort((a, b) => {
      if (a in title_refs) {
        if (a in title_refs && b in title_refs) {
          return title_refs[a] - title_refs[b] // both are proper titles.
        } else {
          return -1 // B does not have proper title.
        }
      } else {
        if (b in title_refs) {
          return 1 // A does not have proper title.
        } else {
          return a.localeCompare(b) // Neither has proper title and alphabetical order works fine.
        }
      }
    })
  }
  function setClientLists(acct_id) {
    var ref_client_ids_by_title = {}
    const active_clients = getMyClients(acct_id)
    active_clients.forEach((client: client) => {
      const contacts = client.contacts
      const roles = client.roles

      Object.keys(contacts).forEach((title) => {
        if (contacts[title]) {
          if (contacts[title].includes(acct_id)) {
            if (title in ref_client_ids_by_title) {
              ref_client_ids_by_title[title].push(client.id)
            } else {
              ref_client_ids_by_title[title] = [client.id]
            }
          }
        }
      })
      Object.keys(roles).forEach((title) => {
        if (roles[title]) {
          if (roles[title].includes(acct_id)) {
            if (title in ref_client_ids_by_title) {
              ref_client_ids_by_title[title].push(client.id)
            } else {
              ref_client_ids_by_title[title] = [client.id]
            }
          }
        }
      })
    })

    setAllRefClientIDsByTitle(ref_client_ids_by_title)
    var all_titles = Object.keys(ref_client_ids_by_title)

    var accordion_obj = {}

    all_titles = sortTitles(all_titles)

    all_titles.forEach((title) => {
      accordion_obj[title] = true
    })
    setAllTitlesInOrder(all_titles)
    setAccordion(accordion_obj)
  }
  function toggleAccordion(title) {
    setAccordion({ ...accordion, [title]: !accordion[title] })
  }
  function sortClientsByName(clients: client[]) {
    if (clients && clients.length > 1) {
      const clients_in_order = clients.sort((a, b) =>
        a.name.localeCompare(b.name)
      )
      return clients_in_order
    } else {
      return clients
    }
  }
  function getCleintsFromIDs(client_ids, clients) {
    var client_docs: client[] = []
    if (clients && clients.length > 0 && client_ids && client_ids.length > 0) {
      for (let i = 0; i < clients.length; i++) {
        if (clients[i]) {
          const client = clients[i]
          if (client_ids.includes(client.id)) {
            client_docs.push(client)
          }
        }
      }
    }
    return client_docs
  }

  function getMyClients(acct_id: string) {
    var clients_assigned_to_user: client[] = []
    const active_clients = clients.filter((client) => {
      return client.active
    })
    for (let i in active_clients) {
      for (let role_title in active_clients[i].roles) {
        const roles = active_clients[i].roles
        if (roles[role_title].includes(acct_id)) {
          if (!clients_assigned_to_user.includes(active_clients[i]))
            clients_assigned_to_user.push(active_clients[i])
        }
      }
    }
    return clients_assigned_to_user
  }
  async function getRecentChanges() {
    const current_user_clients: client[] = getMyClients(account.id)
    var client_ids: string[] = []
    const max_client_ids = 29
    var counter = 0
    current_user_clients.forEach((client) => {
      if (max_client_ids > counter) {
        client_ids.push(client.id)
        counter += 1
      }
    })
    if (client_ids.length > 0) {
      const q = query(
        collection(db, 'change_log'),
        where('subject_id', 'in', client_ids),
        limit(20)
      )
      const querySnapshot = await getDocs(q)
      var changes: change[] = []
      querySnapshot.forEach((docSnap) => {
        changes.push(ensureChangeType(docSnap.data()))
      })
      setRecentChanges(changes)
    } else {
      setRecentChanges([])
    }
  }
  function RecentChanges() {
    const TABLE_HEAD = ['Author', 'Field', 'Old Value', 'New Value', 'Date']
    return (
      <div className="max-w-96 h-60 w-full overflow-auto rounded-lg border border-gray-300 shadow-md">
        <table className="w-full table-auto overflow-auto text-left">
          <thead className="">
            <tr className="">
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="basis-1/5 border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                >
                  <Typography
                    placeholder="."
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          {recentChanges ? (
            <>
              {recentChanges.sort((a, b) => {
                return b.actionDate - a.actionDate
              }).length > 0 ? (
                <tbody>
                  {recentChanges.map((change, index) => {
                    const classes = 'p-2 overflow-x-auto'
                    const author_id = change.author_id
                    var field = ''
                    var subject_name = ''
                    var author_name = ''
                    for (let i in accounts) {
                      if (accounts[i].id === author_id) {
                        author_name = accounts[i].name
                      }
                      if (change.subject_type === 'account') {
                        if (accounts[i].id === change.subject_id) {
                          subject_name = accounts[i].name
                        }
                      }
                    }
                    if (change.subject_type === 'client') {
                      for (let i in clients) {
                        if (clients[i].id === change.subject_id) {
                          subject_name = clients[i].name
                        }
                      }
                    }
                    field =
                      String(subject_name) + '/' + String(change.field_name)

                    return (
                      <tr
                        key={subject_name + String(index)}
                        className="border-b hover:bg-blue-50"
                        onClick={() =>
                          setNavigate(
                            '/view/' +
                              String(change.subject_type) +
                              '#' +
                              String(change.subject_id)
                          )
                        }
                      >
                        <td className={classes}>
                          <Typography
                            placeholder="."
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {author_name}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder="."
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {field}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder="."
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {change?.old_value}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder="."
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {change?.new_value}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            placeholder="."
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {change?.timestamp}
                          </Typography>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : (
                <div className="w-full p-4">No Changes Found.</div>
              )}
            </>
          ) : (
            <div>
              <div className="p-3">
                <Loading />
              </div>
            </div>
          )}
        </table>
      </div>
    )
  }
  function MyChanges() {
    const TABLE_HEAD = ['Field', 'Old Value', 'New Value', 'Date']
    return (
      <div>
        <div className="max-w-96 h-60 w-full overflow-auto rounded-lg border border-gray-300 shadow-md">
          <table className="w-full table-auto overflow-auto text-left">
            <thead className="">
              <tr className="">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="basis-1/5 border-b border-blue-gray-100 bg-blue-gray-50 p-2"
                  >
                    <Typography
                      placeholder="."
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            {myChanges ? (
              <>
                {myChanges.sort((a, b) => {
                  return b.actionDate - a.actionDate
                }).length > 0 ? (
                  <tbody>
                    {myChanges.map((change, index) => {
                      const classes = 'p-2 overflow-x-auto'
                      const author_id = change.author_id
                      var field = ''
                      var subject_name = ''
                      var author_name = ''
                      var new_value = change.new_value
                      var old_value = change.old_value
                      for (let i in accounts) {
                        if (accounts[i].id === author_id) {
                          author_name = accounts[i].name
                        }
                        if (change.subject_type === 'account') {
                          if (accounts[i].id === change.subject_id) {
                            subject_name = accounts[i].name
                          }
                        }
                        if (accounts[i].id === old_value) {
                          old_value = accounts[i].name
                        }
                        if (accounts[i].id === new_value) {
                          new_value = accounts[i].name
                        }
                      }
                      if (change.subject_type === 'client') {
                        for (let i in clients) {
                          if (clients[i].id === change.subject_id) {
                            subject_name = clients[i].name
                          }
                          if (clients[i].id === old_value) {
                            old_value = clients[i].name
                          }
                          if (clients[i].id === new_value) {
                            new_value = clients[i].name
                          }
                        }
                      }
                      field =
                        String(subject_name) + ' / ' + String(change.field_name)
                      return (
                        <tr
                          key={subject_name + String(index)}
                          className="border-b hover:bg-blue-50"
                          onClick={() =>
                            setNavigate(
                              '/view/' +
                                String(change.subject_type) +
                                '#' +
                                String(change.subject_id)
                            )
                          }
                        >
                          <td className={classes}>
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {field}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {old_value}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {new_value}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              placeholder="."
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {change?.timestamp}
                            </Typography>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                ) : (
                  <div className="w-full p-4">No Changes Found.</div>
                )}
              </>
            ) : (
              <div>
                <div className="p-3">
                  <Loading />
                </div>
              </div>
            )}
          </table>
        </div>
      </div>
    )
  }
  const [upkeepRan, setUpkeepRan] = useState(false)
  const reloadData = useContext(ReloadDataContext)

  useEffect(() => {
    if (
      firstLoad &&
      accounts.length > 0 &&
      clients.length > 0 &&
      account.id !== undefined &&
      reloadData
    ) {
      reloadData()
      setClientLists(account.id)
      setFirstLoad(false)
    }
    if (!upkeepRan && clients.length > 0 && accounts.length > 0) {
      setUpkeepRan(true)
      runUpkeep(clients, accounts)
    }
    if (!myChanges && account) {
      getMyChanges()
    }
    if (!recentChanges && account && clients) {
      getRecentChanges()
    }
  })
  async function getMyChanges() {
    const q = query(
      collection(db, 'change_log'),
      where('author_id', '==', account.id),
      limit(20)
    )
    const changes: change[] = []
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      changes.push(ensureChangeType(doc.data()))
    })
    setMyChanges(changes)
  }

  async function runUpkeep(clients, accounts) {
    await upkeep(clients, accounts)
    console.log('upkeep was run')
  }
  const [navigate, setNavigate] = useState('')

  return (
    <>
      <div className="flex flex-row min-w-fit">
        {navigate !== '' && <Navigate to={navigate} />}
        <div className="pl-4 w-full h-1/2">
          <Card placeholder="." className="flex w-full flex-col overflow-auto">
            <div className=" py-2 px-4">
              <div>
                <Typography placeholder="." variant="h4" color="black">
                  My Clients
                </Typography>
                <hr className="border-blue-600" />
              </div>
              <div className="w-full px-2">
                {allTitlesInOrder ? (
                  <div>
                    {allTitlesInOrder.length > 0 ? (
                      <>
                        {allTitlesInOrder.length === 1 ? (
                          <>
                            {allTitlesInOrder.map((title, index) => {
                              return (
                                <div
                                  className="w-full flex flex-col"
                                  key={
                                    String(index) + '_clients_titled_section'
                                  }
                                >
                                  <div className="flex flex-row flex-wrap">
                                    {sortClientsByName(
                                      getCleintsFromIDs(
                                        allRefClientIDsByTitle[title],
                                        clients
                                      )
                                    ).map((client, sub_index) => {
                                      if (client) {
                                        return (
                                          <ClientCard
                                            client={client}
                                            pinned={false}
                                            key={sub_index}
                                          />
                                        )
                                      } else {
                                        return <></>
                                      }
                                    })}
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        ) : (
                          <>
                            {allTitlesInOrder.map((title, index) => {
                              return (
                                <div
                                  className="w-full flex flex-col"
                                  key={
                                    String(index) + '_clients_titled_section'
                                  }
                                >
                                  <Accordion
                                    placeholder={''}
                                    open={
                                      accordion[title] &&
                                      accordion[title] === true
                                    }
                                  >
                                    <AccordionHeader
                                      placeholder={''}
                                      onClick={() => toggleAccordion(title)}
                                    >
                                      <div className="w-full">
                                        <Typography
                                          placeholder={'.'}
                                          variant="h5"
                                        >
                                          {title}
                                        </Typography>
                                      </div>
                                    </AccordionHeader>
                                    <AccordionBody>
                                      <div className="flex flex-row flex-wrap">
                                        {sortClientsByName(
                                          getCleintsFromIDs(
                                            allRefClientIDsByTitle[title],
                                            clients
                                          )
                                        ).map((client, sub_index) => {
                                          if (client) {
                                            return (
                                              <ClientCard
                                                client={client}
                                                pinned={false}
                                                key={sub_index}
                                              />
                                            )
                                          } else {
                                            return <></>
                                          }
                                        })}
                                      </div>
                                    </AccordionBody>
                                  </Accordion>
                                </div>
                              )
                            })}
                          </>
                        )}
                      </>
                    ) : (
                      <div className="p-4">No Clients Assigned.</div>
                    )}
                  </div>
                ) : (
                  <div>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
      {/*
      <div className="pl-4 pt-4 pb-2 min-w-fit">      
        {myClients && <UpcomingKompassCard myClients={myClients} />}
      </div>
      */}
      <div className="pl-4 pt-4 pb-2 min-w-fit">
        <Card placeholder={'.'}>
          <div className="w-full px-4 py-2">
            <div className="p-2">
              <div className="pb-2">
                <Typography placeholder="." variant="h5" color="black">
                  Recent Changes to My Clients
                </Typography>
                <hr className="border-blue-600" />
              </div>
              <div>
                {!(recentChanges === undefined) ? (
                  <div>
                    <RecentChanges />
                  </div>
                ) : (
                  <div>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="pl-4 py-2 min-w-fit">
        <Card placeholder={'.'}>
          <div className="w-full px-4 py-2">
            <div className="p-2">
              <div className="pb-2">
                <Typography placeholder="." variant="h5" color="black">
                  My Changes
                </Typography>
                <hr className="border-blue-600" />
              </div>
              <div>
                {!(myChanges === undefined) ? (
                  <div>
                    <MyChanges />
                  </div>
                ) : (
                  <div>
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}
